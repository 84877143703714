/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 2 Mar 2022
*/
// imports
import './css/header.css';
import './css/variables.css';
import BrandIcon from './images/brand_icon.png';
import BrandImageLightMode from './images/QRnow_ic2_lightmode.png';
import BrandImageDarkMode from './images/QRnow_ic2_darkmode.png';
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch'
import Cookies from 'js-cookie'


// The <Header/> component
export default function Header(){
  // darkMode variable
  const [darkMode, setDarkMode] = useState(false);
  // Brand image reference
  const BrandImageRef = useRef(null);

  /* Check the mode (dark or light)
  if mode Cookie is not set by default
  enable dark mode */
  useEffect(()=>{
    // Document body
    const body = document.body;
    
    Cookies.get('mode') ?? Cookies.set('mode', 'dark');
    if(Cookies.get('mode') === 'dark'){
      // Change darkMode variable to true
      setDarkMode(true);
      // Add the .dark__mode class to body
      body.classList.add('dark__mode');
      // Change brand image to BrandImageDarkMode
      BrandImageRef.current.src = BrandImageDarkMode;
    }
    else{
      // Change drakMode variable to false
      setDarkMode(false);
      // Add the .light__mode class to body
      body.classList.add('light__mode');
      // Change brand image to BrandImageLightMode
      BrandImageRef.current.src = BrandImageLightMode;
    }
  }, [])

  /* This method is used to change the mode (dark or light) 
  toggles between modes */
  const modeChange = () =>{
    // Document body
    const body = document.body;

    /* If darkMode is 'true' then we need to set
    light mode else we need to set dark mode*/
    if(darkMode){
      setDarkMode(false);
      Cookies.set('mode', 'light');
      /* Check if body contains the .dark__mode class
      If yes then remove it*/
      if(body.classList.contains('dark__mode'))
        body.classList.remove('dark__mode')

      // Add the .light__mode class to body
      body.classList.add('light__mode')
      // Change brand image to BrandImageLightMode
      BrandImageRef.current.src = BrandImageLightMode;
    }
    else{
      setDarkMode(true);
      Cookies.set('mode', 'dark');
      /* Check if body contains the .light__mode class
      If yes then remove it*/
      if(body.classList.contains('light__mode'))
        body.classList.remove('light__mode')
      
      // Add the .dark__mode class to body
      body.classList.add('dark__mode')
      // Change brand image to BrandImageDarkMode
      BrandImageRef.current.src = BrandImageDarkMode;
    }
  }

  return(
    <Fragment>
        <header className="header">
        <div className="header__div">
          <div className="header__icon_div">
            <img className="header__icon" 
              src={ BrandIcon } 
              alt="Brand Icon"/>
          </div>
          <Link to="/">
            <img className="header__brand" 
              ref={ BrandImageRef }
              src={ BrandImageLightMode } 
              alt="QR NOW" />
          </Link>
        </div>
        <div className="header__switch_div">
          <Switch 
            onChange={ modeChange }
            checked={ darkMode }
            onColor="#FFFFFF"
            offColor="#202020"
            onHandleColor="#00E278"
            handleDiameter={ 30 }
            boxShadow="0 0 3px 1px rgba(0, 0, 0, 0.1)"
            height={ 19 }
            width={ 43 }
            uncheckedIcon={ false }
            checkedIcon={ false } />
        </div>
      </header>
    </Fragment>
  )
}