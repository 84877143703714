/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 10 Feb 2022
*/
// imports
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import './css/fonts.css'
import './css/variables.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'
import ApiDoc from './Documentation'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

ReactDOM.render(
  <Fragment>
    <Router>
      <Routes>
        <Route exact path="/" element={ <App /> } />
        <Route path="/api-doc" element={ <ApiDoc /> } />
      </Routes>
    </Router>
  </Fragment>,
  document.getElementById('root')
);