/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 2 Mar 2022
*/
// imports
import './css/footer.css';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';


// The <Footer /> component
export default function Footer(props){
  return(
    <Fragment>
      <footer className="footer">
        <div className="footer__div">
          <p>Designed & Developed by 
            <a className="dev__tag" 
              target="_blank"
              rel="noreferrer noopener"
              href="https://bryancaldeira.dev">&nbsp;Bryan Caldeira</a>
          </p>
          <Link to={ props.isDoc ? "/" : "/api-doc" } 
            className="api__tag">{ props.isDoc ? 'Back to QR Now': 'API documentation'}</Link>
        </div>
      </footer>
    </Fragment>
  )
}