/* 
@author: Bryan Caldeira - bryancaldeira22@gmail.com
@file_creation_date: 13 Nov 2021
@last_edit_date: 10 Feb 2022
*/
// imports
import './css/popup.css';
import './css/app.css'
import DummyQR from './images/dummy_qr.svg'
import Header from './Header';
import React, { useState, useRef, Fragment, useEffect } from 'react';
import { TwitterPicker } from 'react-color';
import axios from 'axios';
import Popup from 'reactjs-popup';
import isValidFilename from 'valid-filename';
import Select from 'react-select';
import { ReactSVG } from'react-svg';
import Footer from './Footer';

// The API URL
const api_url = 'https://7f83iqlbti.execute-api.ap-south-1.amazonaws.com/prod/get_qr';

const backgroundColorsArray = ['#FFFFFF', '#000000', 'transparent', '#FF0075', '#F58840'];
const foregroundCOlorsArray = ['#000000', '#FFFFFF', '#E5DCC3', '#A3F7BF', '#E1CCEC'];

// The <App /> component
export default function App(){
  // Background color
  const [bgColor, setBgColor] = useState('#FFFFFF');
  // Foreground colour
  const [fgColor, setFgColor] = useState('#000000');
  // File type
  const [fileFormat, setFileFormat] = useState('PNG');
  // File resolution
  const [fileRes, setFileRes] = useState(200);
  // File information
  const [fileInfo, setFileInfo] = useState({});
  // Popup trigger
  const [popupOpen, setPopupOpen] = useState(false);
  // Warning message
  const [warningMess, setWarningMess] = useState('');
  // Textarea reference
  const embTxt = useRef(null);
  // Filename input reference
  const fileName = useRef(null);
  // Submit button reference
  const subBtn = useRef(null);

  useEffect(()=> window.scrollTo(0, 0), [])


  // File formats option
  const fileFormatOptions = [
    { value: 'PNG', label: 'PNG' },
    { value: 'JPEG', label: 'JPEG' },
  ];


  // File resolution options
  const fileResolutionOptions = [
    { value: '50', label: '50x50' },
    { value: '100', label: '100x100' },
    { value: '200', label: '200x200' },
    { value: '400', label: '400x400' },
    { value: '600', label: '600x600' },
    { value: '800', label: '800x800' },
    { value: '1000', label: '1000x1000' },
  ];


  /* Change the submit button status */
  const changeBtnStatus = (status, text) =>{
    subBtn.current.disabled = status;
    subBtn.current.innerHTML = text;
  }


  /* This method makes a API call to 
  get the QR Image in bse64 format */
  const getQR = async() =>{

    if(embTxt.current.value){
      setFileInfo({});
      setWarningMess(null);
      changeBtnStatus(true, "Loading...");

      // API call using POST method
      await axios.post(api_url, {
        "emb_data": `${ embTxt.current.value }`,
        "bg_colour": `${ bgColor }`,
        "fg_colour": `${ fgColor }`,
        "resolution": `${ fileRes }`,
        "file_type": `${ fileFormat }`
      },
      { 
        headers: {
          "X-Api-Key": process.env.REACT_APP_API_KEY 
        }
      }).then(response=>{
        /* Check if API call was successful
        else display error message */
        if(response.data.success){
          // Set the file information
          setFileInfo({
            qr_data: response.data['qr_data'],
            qr_version: response.data['qr_version'],
            qr_resolution: response.data['qr_resolution'].join(' x '),
            file_size: response.data['file_size'],
            file_type: response.data['file_type']
          });
        }
        else{
          // Set the warning message
          setWarningMess(response.data.error_message);
        }
      }).catch(error=>{
        console.log(error);
        // Set the warning message
        setWarningMess('Error!!!');
      });

      changeBtnStatus(false, "SUBMIT");
    }
    else{
      // Set the warning message
      setWarningMess('Data to be embedded into the QR cannot be empty');
    }

    // Open the popup
    setPopupOpen(true);
  }


  /* This method makes the QR downloadable */
  const downloadQr = () =>{
    const fname = fileName.current.value.split('.')[0];
    const ftype = '.'+fileInfo['file_type'].toLowerCase();
    // Create a <a> tag and make it downloadable
    const aTag = document.createElement('a');
    aTag.href = fileInfo.qr_data;

    /* Check if filename passed by the user is
    valid or not and assign the name based on
    the check */
    aTag.download = isValidFilename(fname) ? fname+ftype : 'qr_now'+ftype; 
    aTag.click();
  }


  // Handle foreground colour change
  const fgChange = (color, event) => {
    const fgDiv = document.getElementById('colour__selected_fg').style;
    if(event.type === 'change' || event.type === 'click'){
      document.documentElement.style.setProperty('--svg-fg', color.hex);
      fgDiv.background = color.hex;
      setFgColor(color.hex)
    }
  }

  // Handle background colour change
  const bgChange = (color, event) => {
    const bgDiv = document.getElementById('colour__selected_bg').style;
    if(event.type === 'change' || event.type === 'click'){
      document.documentElement.style.setProperty('--svg-bg', color.hex);
      bgDiv.background = color.hex;
      setBgColor(color.hex)
    }
  }

  return (
    <Fragment>
      <Header />
      <div className="container main__div">
        <div className="row main__row">
          <Popup
            className="popup"
            open={ popupOpen }
            onClose={ ()=>setPopupOpen(false) }
            lockScroll={ true }
            closeOnDocumentClick={true}
            closeOnEscape={ true }
            width={ '100%' }
            position="center center">
            <div className="popup__div">
              <button className="popup__close_btn" 
                onClick={ ()=>setPopupOpen(false) }>X</button>
                { warningMess ? ( <p id="warning__message">{ warningMess }</p> ) :
                  (
                    <div className="qr__img_div">
                      <p className="popup__text text__top">QR VERSION: <span>{ fileInfo.qr_version }</span></p>
                      <p className="popup__text">FILE SIZE: <span>{ fileInfo.file_size }</span></p>
                      <p className="popup__text">RESOLUTION: <span>{ fileInfo.qr_resolution }</span></p>
                      <p className="popup__text">FILE TYPE: <span>{ fileInfo.file_type }</span></p>
                      <img className="qr__img" src={ fileInfo.qr_data } alt="QR CODE" /><br/>
                      <input ref={ fileName } 
                        className='qr__img_input' 
                        type="text" 
                        placeholder='Filename...'/><br/>
                      <button
                        onClick={ ()=>downloadQr() }
                        className="qr__download_btn">
                        DOWNLOAD
                      </button>
                    </div>
                  )
                }
            </div>
          </Popup>
          <div className="col-12 col-md-3 col-lg-3">
            <ReactSVG
              src={ DummyQR }
              beforeInjection={(svg) => {
                svg.classList.add('main__svg')
                svg.querySelector('#qr').classList.add('main__svg_group')
              }}
            />
          </div>
          <div className="col-12 col-md-9 col-lg-9 content__div">
            <textarea ref={ embTxt } className="main__textarea" placeholder="Enter text or URL" />
            <button 
              className="clear__btn"
              onClick={ ()=>embTxt.current.value = '' }>CLEAR</button>
          </div><br/>
          <div className="row color__picker">
            <div className="col-12 col-md-6 col-lg-4 color__picker_col">
              <p className="colour__picker_tag">Choose a background colour</p>
              <div id="colour__selected_bg" />
              <div className="colour__picker_div">
                <TwitterPicker
                  color={ bgColor }
                  colors={ backgroundColorsArray }
                  triangle="hide"
                  onChange={ bgChange }
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 color__picker_col">
              <p className="colour__picker_tag">Choose a foreground colour</p>
              <div id="colour__selected_fg" />
              <div className="colour__picker_div">
                <TwitterPicker
                  color={ fgColor }
                  colors={ foregroundCOlorsArray }
                  triangle="hide"
                  onChange={ fgChange }
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 color__picker_col">
              <div className='row sub__row'>
                <div className="col-12">
                  <p className="file__tag">Choose file type and resolution</p>
                  <Select
                    options={ fileFormatOptions }
                    className='select__tag'
                    classNamePrefix='react__select'
                    placeholder="PNG"
                    isSearchable={ false }
                    onChange={ (e)=>setFileFormat(e.value) }
                  />
                </div>
                <div className="col-12 file__res_col">
                  <Select
                    options={ fileResolutionOptions }
                    className='select__tag'
                    classNamePrefix='react__select'
                    placeholder="200x200"
                    isSearchable={ false }
                    onChange={ (e)=>setFileRes(e.value) }
                  />
                </div>
              </div>
            </div>
          </div>
          <p className="submit__btn_p">
            <button
              onClick={()=>getQR()}
              ref={ subBtn }
              className="submit__btn">SUBMIT</button>
          </p>
        </div>
      </div>
      <Footer isDoc={ false }/>
    </Fragment>
  )
}